@import 'assets/styles/themes/light';
@import 'assets/styles/mixins/theme';

.root {
	width: 80px;
	height: 80px;

	position: relative;

	overflow: hidden;

	background-color: #f8f8f8;

	border-radius: 50%;

	div {
		width: 44%;
		height: 44%;

		position: absolute;
		top: 50%;
		left: 50%;

		transform: translateX(-50%) translateY(-50%);

		svg {
			width: 100%;
			height: 100%;

			transform-origin: center center;
		}
	}
}

@include theme('dark') {
	@import 'assets/styles/themes/dark';

	.root {
		background-color: $color-background-secondary;
	}
}
