@mixin theme($name, $isModule: true) {
	@if $isModule {
		@media (prefers-color-scheme: #{$name}) {
			:global(body.theme-#{$name}) {
				@content;
			}
		}

		:global(body.theme-#{$name}) {
			@content;
		}
	} @else {
		@media (prefers-color-scheme: #{$name}) {
			body.theme-#{$name} {
				@content;
			}
		}

		body.theme-#{$name} {
			@content;
		}
	}
}
