@import 'assets/styles/themes/light';
@import 'assets/styles/mixins/theme';

.root {
	width: 20px;
	height: 20px;

	position: relative;

	cursor: pointer;

	color: $color-primary;

	&.unavailable {
		cursor: default;

		opacity: 0.3;
	}

	svg {
		position: absolute;

		height: 100%;
		width: 100%;
	}
}

@include theme('dark') {
	@import 'assets/styles/themes/dark';

	.root {
		color: $color-font;
	}
}

:global {
	.copyFade {
		&-enter svg {
			opacity: 0;
		}
		&-enter-active svg {
			opacity: 1;
			transition: opacity 300ms;
		}
		&-exit svg {
			opacity: 1;
		}
		&-exit-active svg {
			opacity: 0;
			transition: opacity 300ms;
		}
	}
}
