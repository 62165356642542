.root {
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal {
	width: 480px;
	height: 480px;
	position: absolute;
	inset: 50% auto auto 50%;
	overflow: auto;
	outline: none;
	padding: 40px;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	background: #ffffff;
	box-shadow: 0px 0px 20px rgba(37, 65, 178, 0.25);
	border-radius: 16px;

	display: flex;
	justify-content: center;
	flex-direction: column;

	.loading {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		& > div {
			width: 100px;
			height: 100px;
		}
	}

	.close {
		position: absolute;
		right: 20px;
		top: 20px;

		& > svg {
			cursor: pointer;
		}
	}

	.wrap {
		overflow: hidden;
		width: 100%;
		height: 400px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 20px;
		margin-top: 20px;
	}

	video {
		display: none;
		border-radius: 16px;
		transform: scaleX(-1);
		width: auto;
		height: 400px;
	}

	.videoShow {
		display: block;
	}
}
