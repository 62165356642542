@import '../assets/styles/mixins/theme';
@import '../assets/styles/themes/light';
@import '../assets/styles/themes/color';

$box-shadow-size: 0px 0px 30px;

.root {
	display: flex;

	width: 90%;
	max-width: 440px;
	// min-height: 340px;
	max-height: 100%;

	flex-direction: column;
	justify-content: center;

	background-color: $color-background-primary;

	padding: 24px;

	box-shadow: $box-shadow-size rgba(37, 65, 178, 0.25);

	border-radius: 16px;

	position: relative;

	& > * {
		// flex: 1;
	}

	& > h4 {
		font-weight: 700;
		font-size: 16px;
		color: var(--gray-1);
		text-align: center;
	}

	& > p {
		text-align: center;
		font-size: 14px;
		color: var(--gray-1);
	}
}

.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
}

.wallet {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&_list {
		cursor: pointer;
		background: #f5f6fa;
		border-radius: 65px;
		width: 100%;
		height: 44px;
		display: flex;
		align-items: center;
		padding-left: 24px;
		margin-top: 16px;

		& > img {
			margin-right: 15px;
		}

		& > p {
			color: var(--gray-1);
			font-weight: 700;
			font-size: 16px;
		}
	}
}

.link {
	margin-top: 24px;
	text-align: center;
	color: var(--secondary-3) !important;
}

@include theme('dark') {
	@import '../assets/styles/themes/dark';

	.root {
		background-color: $color-background-primary;

		box-shadow: $box-shadow-size rgba(23, 24, 41, 0.5);
	}
}
