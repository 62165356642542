@import '../mixins/theme';

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&display=swap');

body {
	@import '../themes/light';

	font-family: 'M PLUS Rounded 1c', 'Nunito', sans-serif;
	font-size: 16px;
	color: $color-font;
	font-style: normal;
	font-weight: 400;
	line-height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background-color: $color-background;
}
@include theme('light', false) {
	@import '../themes/light';

	color: $color-font;

	background-color: $color-background;
}
@include theme('dark', false) {
	@import '../themes/dark';

	color: $color-font;

	background-color: $color-background;
}
