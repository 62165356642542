@import 'assets/styles/themes/light';
@import 'assets/styles/mixins/theme';

$font-size: 14px;

.out {
	position: relative;
}

.error {
	position: absolute;
	top: calc(100% + 5px);
	color: #cf3939;
}

.root {
	display: flex;

	padding: 10px 16px;

	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	gap: 10px;

	color: $color-font;

	background-color: $color-background-secondary;

	border-radius: 6px;

	overflow: hidden;

	font-size: 14px;
	margin-bottom: 24px;

	&_hasError {
		margin-bottom: 36px;
		border: 1px solid #cf3939;
	}

	&_hasSuffix {
		font-size: 12px;
	}

	label {
		display: flex;

		flex-grow: 1;

		position: relative;

		cursor: text;

		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;
		gap: 5px;

		input {
			position: absolute;
			top: 0;
			left: 0;

			width: 100%;

			padding: inherit;

			color: inherit;
		}

		span {
			visibility: hidden;
			height: 18px;
		}
	}
}

@include theme('dark') {
	@import 'assets/styles/themes/dark';

	.root {
		color: $color-font-secondary;

		background-color: $color-background-secondary;
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected {
	background-color: #f5f6fa !important;
	background-image: none !important;
	-webkit-box-shadow: 0 0 0 1000px #f5f6fa inset !important;
}
