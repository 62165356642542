.dropdown-network {
	color: white;
	background: #2541b2;
	border-radius: 20px;
	padding: 6px 20px;
}
.Dropdown-control {
	background-color: #2541b2;
	border: none;
	color: white;
	padding: 0;
	cursor: pointer;
}
.Dropdown-menu {
	top: calc(100% - 15px);
	right: 0;
	// width: 130%;
	width: 300px;
	left: 50%;
	transform: translateX(-50%);
	background: #ffffff;
	box-shadow: 0px 0px 20px rgba(37, 65, 178, 0.25);
	border-radius: 16px;
	padding: 24px;
}
.Dropdown-option {
	background: rgba(37, 65, 178, 0.3);
	border-radius: 20px;
	color: white;
	margin-bottom: 8px;
	padding: 8px 24px;
	font-size: 14px;
}
.Dropdown-option.is-selected {
	background: #2541b2;
	color: white;
}
.Dropdown-option:hover {
	background: #2541b2;
	color: white;
}
.Dropdown-option:last-child {
	border-radius: 20px;
}
.Dropdown-arrow {
	display: none;
}

:root {
	--account-center-z-index: 2;
}
