@import 'assets/styles/themes/light';
@import 'assets/styles/mixins/theme';
@import 'assets/styles/themes/color';

.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	// padding: 24px 46px;
	padding-left: 16px;
	z-index: 1;

	&_back {
		// margin-top: 38px;
		// margin-left: 22px;
		// position: absolute;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #ffffff;
		box-shadow: 0px 4px 16px rgba(179, 179, 179, 0.25);
		border-radius: 16px;
		width: auto;
		padding: 0px 20px;
		height: 58px;
		cursor: pointer;
		position: fixed;
		right: 356px;
		top: 16px;
		color: #2541b2;
		font-weight: 700;
		font-size: 16px;

		& > svg {
			width: 26px;
			height: 26px;
			margin-right: 5px;
		}
	}

	&_pancake {
		margin-right: 24px;

		&_mask {
			position: absolute;
			left: -8px;
			top: -8px;
			width: calc(100% + 16px);
			height: calc(100% + 16px);
			overflow: hidden;
			filter: blur(10px);
			z-index: -1;
			border-radius: 16px;
		}

		&_bg {
			position: absolute;
			background: url('../../assets/images/pancakeSwap.png');
			background-repeat: no-repeat;
			background-size: cover;
			width: 120%;
			height: 240%;
			filter: blur(25px);
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			animation: rounde 2s infinite linear;

			@keyframes rounde {
				0% {
					transform: translate(-50%, -50%) rotate(0deg);
				}

				100% {
					transform: translate(-50%, -50%) rotate(360deg);
				}
			}
		}
	}

	&_info {
		flex: 1;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		font-weight: 700;
		position: relative;

		& > div {
			margin-right: 24px;
		}

		&_item {
			display: flex;
			justify-content: center;
			align-items: center;
			background: #ffffff;
			box-shadow: 0px 4px 16px rgba(179, 179, 179, 0.25);
			border-radius: 16px;
			width: auto;
			padding: 0px 20px;
			height: 58px;
			cursor: pointer;
			position: relative;
			right: 332px;
			top: 16px;
			color: #2541b2 !important;

			& > svg {
				margin-right: 5px;
			}
		}

		&_token {
			position: absolute;
			top: calc(100% + 10px);
			width: 100%;
			padding: 8px;
			background: #ffffff;
			box-shadow: 0px 4px 16px rgba(179, 179, 179, 0.25);
			border-radius: 16px;
			display: none;

			&_active {
				display: block;
				animation: growDown 300ms ease-in-out forwards;
				transform-origin: top center;

				@keyframes growDown {
					0% {
						transform: scaleY(0);
					}

					80% {
						transform: scaleY(1.1);
					}

					100% {
						transform: scaleY(1);
					}
				}
			}

			&_list {
				display: flex;
				align-items: center;
				margin-bottom: 8px;
				padding: 8px 16px;

				&:hover {
					background: #f8f8f8;
					border-radius: 16px;
				}

				&:nth-last-child(1) {
					margin-bottom: 0;
				}

				& > svg {
					margin-right: 5px;
				}

				&_disable {
					opacity: 0.5;
					pointer-events: none;
				}
			}

			&_backdrop {
				position: fixed;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				z-index: -1;
				cursor: auto;
			}
		}
	}
}

@include theme('dark') {
	@import 'assets/styles/themes/dark';

	.header {
		color: $color-font-secondary;
	}
}
