@import 'assets/styles/themes/light';
@import 'assets/styles/mixins/theme';

.root {
	min-width: 240px;

	cursor: pointer;

	padding: 8px 32px;

	color: #ffffff;
	font-weight: 700;
	font-size: 18px;

	background-color: $color-primary;

	border-radius: 30px;
}

button {
	&:disabled {
		opacity: 0.5;
	}
}

@include theme('dark') {
	@import 'assets/styles/themes/dark';

	.root {
		color: $color-primary;
		background: none;

		outline: 2px solid $color-primary;
	}
}
