@import 'assets/styles/mixins/theme';

.root {
	position: relative;

	&::before {
		content: '';

		display: block;

		padding-top: 100%;
	}

	svg {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;
	}
}

@include theme('dark') {
	.root {
		filter: invert(1);
	}
}
