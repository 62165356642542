.txhash {
	width: 60%;
	display: flex;
	& > span {
		font-weight: 700;
		margin-right: 5px;
	}
	& > a {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		color: var(--secondary-3) !important;
		position: relative;
		display: block;
		&::after {
			content: '';
			position: absolute;
			bottom: 0px;
			left: 0px;
			width: 100%;
			height: 1px;
			background-color: var(--secondary-3);
		}
	}
}
