@import 'assets/styles/themes/light';
@import 'assets/styles/mixins/theme';

.root {
	width: 80px;
	height: 80px;

	position: relative;

	overflow: hidden;

	background-color: $color-background-secondary;

	border-radius: 50%;

	div {
		width: 65%;
		height: 65%;

		position: absolute;
		top: 50%;
		left: 50%;

		transform: translateX(-50%) translateY(-50%);

		svg {
			width: 100%;
			height: 100%;

			color: $color-primary;

			transform-origin: center center;

			animation: spin 800ms ease-in-out infinite;
		}
	}
}

@include theme('dark') {
	@import 'assets/styles/themes/dark';

	.root {
		background-color: $color-background-secondary;

		div {
			svg {
				color: $color-font;
			}
		}
	}
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}
