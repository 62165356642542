.root {
	background: #222222 !important;
	border-radius: 7px !important;

	&::after {
		border-left: 9px solid transparent !important;
		border-right: 9px solid transparent !important;
		border-top-width: 7px !important;
	}
}
