%h {
	font-weight: 600;
}

h {
	&2 {
		font-size: 24px;
		line-height: 26.4px;

		@extend %h;
	}

	&3 {
		font-size: 18px;
		line-height: 19.8px;

		@extend %h;
	}
}
