.network-buttons {
	cursor: pointer;
	display: block;
	background: #f5f6fa;
	border-radius: 65px;
	color: var(--gray-1);
	text-align: center;
	margin: auto;
	margin-bottom: 16px;
	width: 256px;
	padding: 15px 30px;
	font-weight: 700;
}
.network-buttons:nth-last-child(1) {
	margin-bottom: 0;
}
