.root {
	display: flex;

	width: 100%;
	// height: 100%;

	// overflow: hidden;
	padding-top: 130px;
	padding-bottom: 30px;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
