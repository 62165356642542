@import '../../assets/styles/mixins/theme';
@import '../../assets/styles/themes/color';

.root {
	display: flex;

	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-end;

	border-bottom: 1px solid var(--gray-4);

	&::before,
	&::after {
		content: '';

		display: block;
		flex-grow: 1;

		align-self: stretch;

		// border-bottom: 1px solid #e3e3e3;
	}
	&::before {
		max-width: 20px;
	}

	& > button {
		cursor: pointer;

		color: var(--gray-1);
		font-weight: 700;

		padding: 4px;

		border-top-left-radius: 4px;
		border-top-right-radius: 4px;

		width: 50%;

		&.active {
			border-bottom: 2px solid #2541b2;
		}

		& + button {
			margin-left: -1px;
		}
	}
}

@include theme('dark') {
	.root {
		&::before,
		&::after {
			border-color: #45476b;
		}

		& > button {
			border-color: #45476b;
		}
	}
}
