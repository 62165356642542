@import 'assets/styles/themes/light';
@import 'assets/styles/mixins/theme';

.root {
	display: flex;

	padding: 80px 104px;

	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;

	.title {
		margin-top: 16px;

		text-align: center;
	}

	.info {
		color: $color-font-secondary;
	}
}

@include theme('dark') {
	@import 'assets/styles/themes/dark';

	.root {
		.info {
			color: $color-font-secondary;
		}
	}
}

.network {
	display: flex;

	padding: 8px;

	flex-direction: column;
	justify-content: center;
	align-items: center;

	.title {
		margin-top: 8px;

		text-align: center;
	}

	.info {
		margin-top: 8px;
		margin-bottom: 24px;
		color: $color-font-secondary;
	}
}
