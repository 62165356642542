.root {
	display: flex;

	flex-direction: column;
	// gap: 24px;

	.qrcode div {
		width: 200px;

		margin: auto;
		margin-bottom: 16px;
	}

	.submitBtn {
		margin-top: auto;
		margin-right: auto;
		margin: auto;

		&_disable {
			opacity: 0.5;
		}
	}
}

.input {
	margin-bottom: 24px;
}

.label {
	font-weight: 700;
	color: #222222;
	margin-bottom: 8px;
}

.line {
	position: relative;
	height: 1px;
	width: 440px;
	background-color: var(--gray-4);
	left: -24px;
	margin-bottom: 24px;
}

.showQrCode {
	text-decoration: underline;
	color: var(--secondary-3);
	position: absolute;
	bottom: -24px;
	right: 0;
	cursor: pointer;

	&.disabled {
		cursor: auto;
		color: var(--gray-3);
	}
}

.onlyUSDT {
	width: 275px;
	margin-top: 8px;
	padding: 16px;
	text-align: center;
	background: rgba(36, 123, 255, 0.1);
	border-radius: 4px;
	color: var(--gray-2);
	position: absolute;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	bottom: 0;
	right: -300px;
	background: #ffffff;
	box-shadow: 0px 0px 20px rgba(37, 65, 178, 0.25);
	border-radius: 16px;
	font-size: 12px;

	& .close {
		display: flex;
		justify-content: end;
		margin-bottom: 10px;
		cursor: pointer;
	}

	& .text {
		display: flex;
		align-items: center;
		justify-content: center;

		& span {
			margin-right: 5px;
		}

		& > a {
			text-decoration: underline;

			& > svg {
				margin-right: 4px;
			}

			&:nth-of-type(1) {
				margin: 0 4px;
			}
		}
	}
}

.dropdown {
	& > div {
		font-size: 16px;
	}
}
