:root {
	--account-center-z-index: 2;
}

.warning {
	&-modal {
		position: absolute;
		inset: 50% auto auto 50%;
		overflow: auto;
		outline: none;
		padding: 24px;
		max-width: 440px;
		width: 90%;
		margin-right: -50%;
		transform: translate(-50%, -50%);
		background: #ffffff;
		box-shadow: 0px 0px 20px rgba(37, 65, 178, 0.25);
		border-radius: 16px;

		& > h3 {
			font-weight: 700;
			font-size: 16px;
			text-align: center;
			color: var(--gray-1);
			margin-bottom: 4px;
		}

		& > p {
			text-align: center;
			color: var(--gray-1);
			font-size: 14px;
			margin-bottom: 40px;
		}

		&-icon {
			margin: auto;
			margin-bottom: 8px;
		}

		&-button {
			cursor: pointer;
			display: block;
			border-radius: 65px;
			text-align: center;
			margin: auto;
			margin-bottom: 16px;
			width: 256px;
			padding: 10px 30px;
			font-weight: 700;
		}
	}
}
