@import '../themes/color';

.dropdown {
	&-style {
		&-contorl {
			background: #f5f6fa !important;
			border-radius: 4px !important;
			color: var(--gray-1) !important;
			padding: 10px 12px !important;
			margin-bottom: 24px !important;
			font-size: 14px !important;
		}

		&-arrow {
			display: block !important;
			background-image: url('../../../assets/icons/arrow.svg');
			right: 15px !important;
			top: 18px !important;
			border: none !important;
			width: 13px !important;
			height: 8px !important;
			background-repeat: no-repeat;

			&-disable {
				display: none !important;
			}
		}

		&-menu {
			width: 100% !important;
			padding: 8px !important;
			box-shadow: 0px 4px 10px rgba(25, 17, 62, 0.1) !important;
			border-radius: 4px !important;

			& .Dropdown-option {
				color: var(--gray-1);
				background-color: white;
				border-radius: 4px !important;

				&.is-selected,
				&:hover {
					color: var(--gray-1) !important;
					background: rgba(34, 123, 255, 0.1) !important;
				}
			}

			& .Dropdown-option:nth-last-child(1) {
				margin-bottom: 0 !important;
			}
		}
	}
}

.network {
	&_modal {
		position: absolute;
		inset: 50% auto auto 50%;
		overflow: auto;
		outline: none;
		padding: 24px;
		max-width: 440px;
		width: 90%;
		margin-right: -50%;
		transform: translate(-50%, -50%);
		background: #ffffff;
		box-shadow: 0px 0px 20px rgba(37, 65, 178, 0.25);
		border-radius: 16px;

		& > h3 {
			font-weight: 700;
			font-size: 16px;
			text-align: center;
			color: var(--gray-1);
			margin-bottom: 4px;
		}

		& > p {
			text-align: center;
			color: var(--gray-1);
			font-size: 14px;
			margin-bottom: 24px;
		}

		&-icon {
			margin: auto;
			margin-bottom: 8px;
		}

		&-buttons {
			cursor: pointer;
			display: block;
			background: #f5f6fa;
			border-radius: 65px;
			color: var(--gray-1);
			text-align: center;
			margin: auto;
			margin-bottom: 16px;
			width: 256px;
			padding: 15px 30px;
			font-weight: 700;
		}

		&-buttons:nth-last-child(1) {
			margin-bottom: 0;
		}
	}
}
