@import 'assets/styles/themes/light';
@import 'assets/styles/mixins/theme';

.root {
	.label {
		margin-bottom: 8px;

		font-size: 1rem;
		font-weight: 700;
	}
}

.disabled {
	opacity: 0.5;
}

@include theme('dark') {
	@import 'assets/styles/themes/dark';

	.root {
		.label {
			color: $color-font-secondary;
		}
	}
}
