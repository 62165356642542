@import 'assets/styles/themes/light';
@import 'assets/styles/mixins/theme';

$font-size: 14px;

.root {
	position: relative;

	font-size: $font-size;

	user-select: none;

	&.disabled {
		opacity: 0.5;

		.input {
			cursor: auto !important;
		}
	}

	.input {
		display: flex;

		cursor: pointer;

		padding: 10px 16px;

		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;

		background-color: $color-background-secondary;

		border-radius: 6px;

		overflow: hidden;

		&_hasError {
			margin-bottom: 36px;
			border: 1px solid #cf3939;
		}

		.body {
			display: flex;

			flex-grow: 1;

			flex-direction: row;
			flex-wrap: nowrap;
			align-items: center;
			gap: 8px;

			min-height: 20px;

			color: var(--gray-1);
		}

		.arrow {
			svg {
				width: 10px;
			}
		}
	}

	.popper {
		width: 100%;

		position: absolute;
		top: 100%;
		left: 0;

		z-index: 1;

		margin-top: 4px;

		padding: 8px;

		background-color: $color-background-primary;

		border: 1px solid #ccc;

		box-shadow: 0px 4px 10px rgb(25 17 62 / 10%);

		border-radius: 4px;

		.option {
			display: flex;

			cursor: pointer;

			padding: 8px 24px;
			margin-bottom: 8px;

			flex-direction: row;
			flex-wrap: nowrap;
			align-items: center;
			gap: 8px;

			border-radius: 4px;

			&:nth-last-of-type(1) {
				margin-bottom: 0;
			}

			&.selected,
			&:hover {
				color: var(--gray-1) !important;
				background: rgba(34, 123, 255, 0.1) !important;
			}

			.label {
				flex-grow: 1;
			}

			svg {
				width: 10px;
			}
		}
	}

	.icon {
		width: 24px;
		height: 24px;
	}
}

.error {
	position: absolute;
	top: calc(100% + 5px);
	color: #cf3939;
}

@include theme('dark') {
	@import 'assets/styles/themes/dark';

	.root {
		.input {
			background-color: $color-background-secondary;
		}

		.popper {
			background-color: $color-background-secondary;

			border-color: $color-primary;

			.option {
				&.selected,
				&:hover {
					color: $color-font;
				}
			}
		}
	}
}
