@import 'assets/styles/themes/light';
@import 'assets/styles/mixins/theme';
@import 'assets/styles/themes/color';

.title {
	position: relative;
	margin-bottom: 24px;

	& > h1 {
		font-weight: 700;
		font-size: 77px;
		background: linear-gradient(147.15deg, #19113e 29.03%, #041d75 84.89%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
		letter-spacing: 1.2px;
	}

	& > p {
		position: absolute;
		left: 7px;
		bottom: 6px;
		font-size: 12px;
		background: linear-gradient(147.15deg, #19113e 29.03%, #041d75 84.89%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
		font-weight: 600;
	}

	& > span {
		position: absolute;
		right: 4px;
		top: 14px;
		font-weight: 700;
		font-size: 22px;
		color: var(--secondary-3);
	}
}

.mini {
	position: absolute;
	zoom: 50%;
}

@include theme('dark') {
	@import 'assets/styles/themes/dark';

	.header {
		color: $color-font-secondary;
	}
}
