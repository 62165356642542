.root {
	display: flex;

	flex-direction: column;

	// gap: 24px;
	.qrcode div {
		width: 130px;

		margin: auto;
	}

	.hideable {
		transition: opacity 300ms ease-in-out 0ms;
	}

	.hidden {
		opacity: 0;

		visibility: hidden;
	}

	.submitBtn {
		margin-top: auto;
		margin-right: auto;
		margin: auto;

		&_disable {
			opacity: 0.5;
		}
	}

	& > p {
		color: var(--gray-2);
		font-size: 12px;
		align-self: flex-end;
	}
}

.input {
	margin-bottom: 24px;
}

.label {
	font-weight: 700;
	color: #222222;
	margin-bottom: 8px;
}

.dropdown {
	& > div {
		margin-bottom: 4px !important;
	}
}

.margin_bottom {
	margin-bottom: 16px;
}

.fee {
	color: var(--gray-2);
	font-size: 12px;
}

.scan {
	cursor: pointer;
}
