$padding-x: 44px;

.root {
	display: flex;

	// padding:0px 24px;

	flex-direction: column;
	justify-content: space-between;
	gap: 40px;

	.tabNav {
		// margin-left: -$padding-x;
		// margin-right: -$padding-x;

		&::before {
			max-width: $padding-x;
		}
	}

	.submitBtn {
		margin-top: auto;
		margin-right: auto;
		margin: auto;

		&_disable {
			opacity: 0.5;
		}
	}
}
