@import './elements/body';
@import './elements/a';
@import './elements/h';
@import './elements/button';
@import './themes/rwd';
@import './elements//dropdown';

* {
	box-sizing: border-box;

	margin: 0;
	margin-block: unset;
	margin-inline: unset;

	padding: 0;

	font: inherit;
	line-height: initial;

	border: none;

	background: transparent;

	outline: none;
}

svg,
img,
canvas {
	display: block;
}

#root {
	width: 100%;
	height: 100%;

	position: absolute;
	top: 0;
	left: 0;

	// overflow: hidden;
}
