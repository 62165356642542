:root {
	--gray-1: #222222;
	--gray-2: #525252;
	--gray-3: #bababa;
	--gray-4: #e9e9e9;
	--secondary-3: #227bff;
	--primary-background: linear-gradient(
		147.15deg,
		#19113e 29.03%,
		#041d75 84.89%
	);
}
